.TitleSkeleton {
  height: 30px;
  width: 200px;
  margin-bottom: 10px;
}

.TabSkeleton {
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.ContentSkeleton {
  height: 500px;
  width: 100%;
}
