.Root {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.TopSection {
  text-align: left;
  margin: 10px 0;
  padding-left: 14px;
}

.BtnGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.TopSectionBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
