.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.FormItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ActionButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;
}
