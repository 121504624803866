:global(.g-root) {
  .MainHeader {
    position: sticky;
    top: 0;
    height: 62px;
    z-index: 2;
    width: 100%;
    background-color: rgba(250, 251, 255, 1);
  }

  .Icon {
    cursor: pointer;
  }

  .SideContentIconItem {
    margin-right: 10px;
  }

  .RightContentItem {
    margin-left: 30px;
  }
}
