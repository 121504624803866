.Root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TabSkeleton {
  height: 30px;
  width: 100%;
}

.FormSkeleton {
  height: 40vh;
  width: 100%;
}

.TableSkeleton {
  height: 40vh;
  width: 100%;
}
