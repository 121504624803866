.Root {
  height: 100%;
  position: relative;
  z-index: 0;
}

.Content {
  height: calc(100% - 62px - 20px - 20px);
  padding: 20px;
  box-sizing: border-box;
}

.Title {
  margin-bottom: 20px;
  width: 100%;
}

.FilterButtonGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.Hidden {
  display: none;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 150px);
  background-color: white;
  border-radius: 16px;
}
