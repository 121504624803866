.Root {
  position: relative;
  z-index: 0;
  height: 100%;
  overflow-y: auto;
}

.DataTableContainer {
  padding: 20px;
  overflow-y: scroll;
}

.Title {
  margin-bottom: 10px;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 140px);
  background-color: white;
  border-radius: 16px;
}
