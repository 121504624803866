.Root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 16px;
  background-color: white;
}

.StatusPrefix {
  display: inline-block;
  margin-right: 5px;
}

.ResultBlock {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.ResultPrefix {
  margin-bottom: 0;
}

.ResultCodeBlock {
  margin-top: 5px;
  background-color: #383838;
  padding: 10px;
}

.ResultCode {
  display: block;
  color: white;
}
