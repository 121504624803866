.Root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.Content {
  padding: 20px;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Checkbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}
