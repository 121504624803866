.HistoryDateFilter {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
}

.Table {
  max-height: calc(100% - 90px) !important;
}
