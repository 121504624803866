@use './normalize.scss' as *;

*,
*::before,
*::after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
