.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Checkbox {
  display: flex;
  align-items: center;
}
