.Root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 48px);
}

.Logo {
  width: 28px;
  opacity: 0.8;
  margin: 0 !important;
  left: 50%;
}

.ActionButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;
}
