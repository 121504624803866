@use './config/config.scss' as *;
@use './fonts/fonts.scss' as *;
@use './variables/colors.scss' as *;

html, body, #root {
  --primary-font: 'Montserrat', sans-serif;

  font-family: var(--primary-font);
  background-color: var(--color-grey-900);
}

body {
  min-height: 100%;
}

:global(.rdt_TableRow) {
  &:nth-child(2n) {
    background-color: #f5f5f5;
  }
}

:global(.content-wrapper) {
  background-color: white;
}

a {
  cursor: pointer;
}

a:not([href]):not([class]) {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
}