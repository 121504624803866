.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
}

.AddedScriptListContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AddedScriptListTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddedScript {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AddedScriptHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.AddedScriptLabel {
  display: inline-block;
  margin-bottom: 5px;
}
