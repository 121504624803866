.Root {
  height: 100%;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.Content {
  padding: 20px;
  box-sizing: border-box;
}

.Title {
  margin-bottom: 10px;
}

.NavBar {
  margin-bottom: 20px;
}
