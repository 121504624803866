.Title {
  margin-bottom: 10px;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.Code {
  font-family: Consolas, monospace;
}

.Fieldset {
  padding: 0;
}

.Dropzone {
  margin-bottom: 10px;
}
