.CertificateInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.CertificateInfoItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.CertificateDeviceLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
