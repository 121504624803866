.StaticGroupsContainer {
  position: relative;
  z-index: 0;
  height: 100%;
  overflow-y: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.DataTableContainer {
  overflow-y: scroll;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 150px);
  background-color: white;
  border-radius: 16px;
}
