.Root {
  height: 100%;
  overflow-y: auto;
}

.Content {
  padding: 20px;
  box-sizing: border-box;
}

.Title {
  margin-bottom: 10px;
}
