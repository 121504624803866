.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 100px;
}

.Empty {
  border-style: dashed;
}

.Dragged {
  background-color: var(--g-color-base-selection);
  border-style: dashed;
}
