.Table {
  max-height: fit-content;

  thead {
    display: none;
  }
}

.ExtensionAttributes {
  margin-top: 20px;
}
