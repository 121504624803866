:global(.g-root) {
  .ContentRoot {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .MainSideMenu {
    --_--vertical-divider-line-color: rgba(0, 0, 0, 0);
    --_--horizontal-divider-line-color: rgba(0, 0, 0, 0);
    --_--background-color: rgba(54, 151, 241, 0.15);
  }

  .MainSideMenuLogo {
    pointer-events: none;
  }
}

:global(.g-button__icon-inner) {
  position: absolute;
  inset: 0;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

:global(.gn-aside-header__header) {
  padding-top: 12px !important;
}
