.HeaderContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow-x: auto;
}

.SearchSkeleton {
  height: 30px;
  width: 250px;
}

.PaginationSkeleton {
  height: 30px;
  width: 500px;
}

.ContentSkeleton {
  height: 200px;
  width: 100%;
}
