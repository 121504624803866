.Root {
  height: calc(100% - 55px);
}

.Terminal {
  background-color: #333;
  color: white;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: scroll;
}

.InputContainer {
  display: flex;
}

.InputPrefix {
  margin-right: 6px;
}

.Input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  color: white;
}

.Output {
  white-space: pre-line;
}

.Spinner {
  --g-color-line-brand: rgba(255, 255, 255, 1);
  margin-right: 10px;
}

.Link {
  margin-right: 5px;
}
