.Root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 100%;
}

.Table {
  max-height: calc(100% - 100px);
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 190px);
  width: 100%;
  background-color: white;
  border-radius: 16px;
}

.RadioButton {
  label {
    margin: 0;
    width: 60px;
  }

  span {
    font-weight: var(--g-text-body-font-weight);
  }
}

.UncheckedBracketCheckbox {
  span {
    color: var(--g-color-private-black-150);
  }
}
