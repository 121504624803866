.Root {
  height: 100%;
  overflow-y: auto;
}

.Content {
  padding: 20px;
  box-sizing: border-box;
}

.Title {
  margin-bottom: 10px;
}

.TitleInput {
  border-bottom: 1px solid var(--g-color-line-generic);
  font-size: var(--g-text-display-1-font-size) !important;
  line-height: var(--g-text-display-1-line-height) !important;
  font-weight: var(--g-text-display-font-weight) !important;
}
