.Root {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.ActionButton {
  margin-bottom: 10px;
  width: fit-content;
}

.DeviceUserList {
  max-height: calc(100% - 80px);
}

.SearchBar {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 300px;
}
