.Root {
  height: 100%;
  padding: 20px;
}

.Fieldset {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Title {
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Content {
  width: 60%;
}

.FormGroup {
  height: 94px;
  margin-bottom: 0;
}

.Label {
  font-family: var(--primary-font);
  user-select: none;
  font-weight: 400;
  margin: 0;
}

.PasswordContainer {
  width: 100%;
  position: relative;
}

.PasswordEye {
  position: absolute;
  top: 22%;
  right: 3%;
  cursor: pointer;
  color: lightgray;
  user-select: none;
}

.Error {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
}
