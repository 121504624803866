.Checkbox {
  margin: 0;
}

.Fieldset {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Title {
  margin-bottom: 20px;
}

.Content {
  min-width: 600px;
  width: 60%;
  margin-bottom: 20px;
}

.FormGroup {
  height: 94px;
  margin-bottom: 0;
}

.Label {
  font-family: var(--primary-font);
  user-select: none;
  font-weight: 400;
  margin: 0;
}

.Error {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

.ActionButtons {
  position: fixed;
  bottom: 60px;
  right: 20px;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}

.Code {
  font-family: Consolas, monospace;
  resize: both;
}

.DataTableContainer {
  margin-top: 10px;
  overflow: scroll;
}
