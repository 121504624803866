.TableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 15px;
}

.ButtonsSkeleton {
  height: 30px;
  width: 300px;
}

.PaginationSkeleton {
  height: 30px;
  width: 500px;
}

.TableSkeleton {
  height: 50vh;
  width: 100%;
}
