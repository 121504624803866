.Content {
  height: calc(100% - 62px - 20px - 20px);
  padding: 20px;
  box-sizing: border-box;
}

.Title {
  margin-bottom: 10px;
}

.NavBar {
  margin-bottom: 20px;
}

.DropdownMenuButton {
  pointer-events: none;
}
