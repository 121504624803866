.Root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.Content {
  padding: 20px;
}

.Title {
  margin-bottom: 20px;
}

.FieldSet {
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding: 0;
}

.Input {
  width: 60%;
  min-width: 200px;
}

.ActionBtnGroup {
  margin-top: auto;
  text-align: right;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 6px;
  }
}

.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
