.Fieldset {
  padding: 0;
}

.ManagementCommandsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 5px;
  width: 100%;
  margin-bottom: 20px;
}

.CommandForm {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
