.Root {
  height: 100%;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.Title {
  margin-bottom: 20px;
}

.Content {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
