.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
  padding-bottom: 5px;
  gap: 20px;
  overflow-x: auto;
}

.Hidden {
  display: none;
}

.Table {
  table {
    width: fit-content;
    min-width: 100%;
    background-color: white;
    border-radius: 16px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  height: fit-content;
  max-height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
}
