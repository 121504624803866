.Table {
  max-height: calc(100% - 90px);
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 190px);
  background-color: white;
  border-radius: 16px;
}
