.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
}

.TopSection {
  display: flex;
  justify-content: space-between;
}

.BtnGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
