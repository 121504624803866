.Root {
  height: 100%;
  overflow-y: scroll;
}

.Filter {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.FilterInput {
  width: 300px;
}
