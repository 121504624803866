.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AddedPackageListContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.AddedPackageListTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddedPackage {
  padding: 20px;
  background-color: white;
  border-radius: 16px;
}

.AddedPackageHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.AddedPackageHeaderManagement {
  display: flex;
  gap: 10px;
}
