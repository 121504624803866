.Root {
  height: 100%;
  overflow-y: auto;
}

.Content {
  display: flex;
  flex-flow: column;
  padding: 20px;
  gap: 10px;
  box-sizing: border-box;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NotConfiguredContainer {
}

.SettingsTitle {
  margin-top: 20px;
  margin-bottom: 12px;
}

.Breadcrumbs {
  background-color: transparent;
}

.Form {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.Fieldset {
  padding: 0;
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.Disclosure {
  margin-top: 10px;
}

.DisclosureContent {
  padding: 10px 0;
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.TestConnectionContainer {
  display: flex;
  align-items: center;
}

.TestConnectionResult {
  margin-left: 10px;
}

.TestConnectionSuccess {
  color: var(--success-green);
}

.TestConnectionFailure {
  color: var(--error-red);
}

.InputPassword {
  margin-bottom: 0;
}

.EditPasswordBtn {
  display: inline-block;
  margin: 4px 0 0;
}

.ShowMappingsBtn {
  display: inline-block;
  margin: 8px 0 0;
}

.EditPasswordBtn {
  display: inline-block;
  margin: 4px 0 0;
}

.EditCaCertificateBtn {
  display: block;
  margin-bottom: 8px;
}

.HasCertificate {
  margin-bottom: 8px;
}

.HasCertificateValue {
  font-weight: bold;
}

.ActionBtnGroup {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.ActionBtn {
  &:not(:last-of-type) {
    margin-right: 6px;
  }
}

.Mappings {
  margin-top: 20px;
}

.ServerError {
  font-size: 14px;
  font-weight: 400;
  color: var(--error-red);
}

.Success {
  font-size: 14px;
  font-weight: 400;
  color: var(--success-green);
}
