.Root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 0;
  max-height: 50vh;
}

.ButtonGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.RadioButton {
  label {
    margin: 0;
    width: 60px;
  }

  span {
    font-weight: var(--g-text-body-font-weight);
  }
}

.UncheckedBracketCheckbox {
  span {
    color: var(--g-color-private-black-150);
  }
}
