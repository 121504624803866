.FormSkeleton {
  height: 30vh;
  width: 100%;
}

.TitleSkeleton {
  height: 30px;
  width: 200px;
  margin-bottom: 10px;
}
