.Root {
  height: 100%;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.DataTableContainer {
  padding: 20px;
  overflow: scroll;
}

.TableDisplayName {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.BtnGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
