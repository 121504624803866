.Fieldset {
  padding: 0;
  height: 100%;
}

.Form {
  height: 100%;
}

.NavBar {
  margin-bottom: 20px;
}
