.Root {
  height: 100%;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.DataTableContainer {
  padding: 20px;
  overflow: scroll;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 150px);
  background-color: white;
  border-radius: 16px;
  margin-top: 20px;
}

.PolicyIconDisabled {
  color: var(--g-color-base-misc-heavy) !important;
}

.PolicyIconHasErrors {
  color: var(--g-color-base-danger-heavy) !important;
}

.PolicyIconEnabled {
  color: var(--g-color-base-positive-heavy) !important;
}
