.Root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ActivationButton {
  width: fit-content;
}

.Hidden {
  display: none;
}
