.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.BuiltInCaInfo {
  padding: 15px;
  background-color: #e0e0e0;
}

.ActionButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.BackupModalForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.BackupModalFormItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
