.Root {
  position: relative;
  width: 100%;
}

.Navbar {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  flex-wrap: nowrap !important;
  overflow: auto !important;
  box-shadow: none !important;
}

.TabButton_size_l {
  font-size: 15px;
}

.TabButton_size_m {
}

.LeftButton_size_m {
  position: absolute;
  top: 4px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  background-color: white;
}

.LeftButton_size_s {
  position: absolute;
  top: 2px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  background-color: white;
}

.RightButton_size_m {
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  background-color: white;
}

.RightButton_size_s {
  position: absolute;
  top: 2px;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  background-color: white;
}
