.Fieldset {
  padding: 0;
}

.NavTabBar {
  margin-bottom: 20px;
}

.StaticGroupActions {
  text-align: right;
  margin-bottom: 20px;
  margin-right: 20px;
}

.Button {
  margin-right: 20px;
}

.GroupTabInput {
  width: 60%;
}
