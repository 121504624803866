.Root {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 10% 0;
}

.Form {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  max-width: 500px;
  max-height: 290px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  gap: 20px;
}

@media (max-width: 900px) {
  .Root {
    min-height: 310px;
  }
}
