.Root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 62px);
}

.Content {
  width: calc(100% - 200px);
  height: 100%;
}
