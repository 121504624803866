.FileInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.FileInfoAndIcon {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.FileInfoAndStatus {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}
