.g-root {
  --g-font-family-sans: 'Inter', sans-serif;

  --g-text-header-font-weight: 600;
  --g-text-subheader-font-weight: 600;
  --g-text-display-font-weight: 600;
  --g-text-accent-font-weight: 600;

  --g-color-base-background: rgba(250, 251, 255, 1);
  --g-color-base-brand: rgba(34, 137, 244, 1);
  --g-color-base-brand-hover: rgba(7, 109, 211, 1);
  --g-color-base-selection: rgba(44, 160, 255, 0.3);
  --g-color-base-selection-hover: rgba(33, 126, 201, 0.3);
  --g-color-line-brand: rgba(117, 155, 255, 1);
  --g-color-text-brand: rgba(117, 155, 255, 1);
  --g-color-text-brand-heavy: rgba(48, 108, 179, 1);
  --g-color-text-brand-contrast: rgba(255, 255, 255, 1);
  --g-color-text-link: rgba(0, 123, 255, 1);
  --g-color-text-link-hover: rgba(0, 86, 179, 1);

  .g-tabs_size_m .g-tabs__item-title {
    font-size: 17px;
  }

  .gn-action-bar-section {
    overflow-x: auto;
  }

  .g-popover {
    display: block;
    position: relative;
  }

  .g-popover__handler {
    display: block;
  }
}
