.TitleSkeleton {
  height: 30px;
  width: 200px;
}

.FormSkeleton {
  height: 30px;
  width: 100%;
}

.TableSkeleton {
  height: 50vh;
  width: 100%;
}
