.Root {
  height: 100%;
}

.Fieldset {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  width: 100%;
}

.ActionButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
