.Root {
  height: 100%;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.DataTableContainer {
  padding: 20px;
  box-sizing: border-box;
  overflow: scroll;
}

.NoDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: calc(100vh - 150px);
  background-color: white;
  border-radius: 16px;
  margin-top: 20px;
}

.BtnGroup {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
