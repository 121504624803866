.ManagementTabNavBar {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  flex-wrap: nowrap !important;
  overflow: auto !important;
  box-shadow: none !important;
}

.ManagementTabContent {
  width: 100%;
  height: calc(100% - 110px);
  overflow-y: scroll;
}
