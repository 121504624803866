.Root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: scroll;
}

.EditableValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.Actions {
  display: flex;
  padding: 10px;
  gap: 10px;
  justify-content: flex-start;
}

.WithoutHead {
  thead {
    display: none;
  }
}
