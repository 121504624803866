.FileInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.FileInfoAndIcon {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.FileInfoAndStatus {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.SuccessIcon {
  color: var(--g-color-base-positive-heavy);
}

.ProgressBarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.ProgressBar {
  width: 100%;
}
