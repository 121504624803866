.Form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-width: 500px;
}

.Input {
  margin: 0;
}

.FileInput {
  max-width: 320px;
}

.Info {
  display: flex;
  flex-direction: column;
}
