.Root {
  height: 100%;
  padding: 20px;
}

.Fieldset {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
}

.FormTitle {
  margin-bottom: 20px;
}

.Code {
  textarea {
    font-family: Consolas, monospace;
  }
}
