.Root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Label {
  display: inline-block;
  margin-bottom: 5px;
}

.Checkbox {
  display: flex;
  align-items: center;
}

.ExtraFormInput {
  margin-left: 20px;

  &.Hidden {
    display: none;
  }
}
